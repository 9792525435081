import React, { useState } from "react";
import { graphql } from "gatsby";
import { getSrc } from "gatsby-plugin-image";
import { useSpring, animated } from 'react-spring';

import Layout from "../components/layout/Layout";
import ContentHTMLWithHoverPopUps from "../components/ContentHTMLWithHoverPopUps";
import ShareButtons from "../components/ShareButtons";
import { Button } from "../components/Button";
import ArticleItem from "../components/ArticleItem";
import HubspotForm from "../components/HubspotForm";

import { pushToGTM, getDefinedTermPopUpItem, pushSAEvent, postFBEvent } from "../helpers";

import white_chevron from "../assets/images/white_chevron.svg";

export default ({ data, pageContext, location }) => {
    const [isMobileFormOpen, setMobileFormOpen] = useState(false);
    const spring = useSpring({ paddingBottom: "50px", opacity: isMobileFormOpen ? 1 : 0, transform: isMobileFormOpen ? "translateY(0px)" : "translateY(1000px)" });
    const handleMobileNavClick = () => setMobileFormOpen((curState) => !curState);

    const definedTermsPopUpItems = data.allContentfulDefinedTerm.nodes.map(getDefinedTermPopUpItem);

    const handleHubSpotFormSubmit = () => {
        pushToGTM({
            'event': 'frends_form_submit',
            'form_type': 'Reports and Whitepapers Form Submit',
            'resource_name': 'Reports and Whitepapers request'
        })
        if (typeof window !== 'undefined') {
            if (window.fbq != null) {
                window.fbq('track', 'ReportsAndWhitepapersRequest')
            }
        }

        postFBEvent('ReportsAndWhitepapersRequest', 'Reports and Whitepapers Form');

        pushSAEvent(`submit_report_download__${data.contentfulReportsAndWhitepapersItem.slug}`);

        /*navigate("/thanks-for-the-registration/")*/
    }

    return (
        <Layout
            title={pageContext.title}
            pageContentId="reports-and-whitepapers-content"
            isWhitepaper={true}
            transparentNavigation={true}
            seoData={{}}
            location={location}
        >
            <main id="reports-and-whitepapers-content" style={{ marginTop: "-80px" }}>
                <div className="mobile-platform-catalogue-container is-hidden-tablet">
                    <animated.div
                        style={spring}
                        className="platform-menu webinar"
                    >
                        <HubspotForm
                            formId={data.contentfulReportsAndWhitepapersItem.hubSpotFormId}
                            loading={<div>Loading...</div>}
                            onSubmit={handleHubSpotFormSubmit}
                        />
                    </animated.div>
                    <nav
                        className="navbar mobile-platform-catalogue webinar"
                        role="navigation"
                        aria-label="get the paper"
                        onClick={handleMobileNavClick}
                    >
                        <div className="navbar-item">
                            <p className="is-white size-24" style={{ marginLeft: '40px' }}>get the paper</p>
                        </div>
                        <div className="navbar-item chevron">
                            <img
                                src={white_chevron}
                                style={{
                                    verticalAlign: "middle",
                                    transform: isMobileFormOpen ? "rotate(0deg)" : "rotate(180deg)", transition: "all .5s ease"
                                }}
                            />
                        </div>
                    </nav>
                </div>

                <section className="hero is-medium is-hidden-mobile" style={{ background: `url(${getSrc(data.bg)}) no-repeat center/cover` }}>
                    <div className="hero-body">
                        <div className="container">
                            <div className="columns is-vcentered is-flex is-flex-wrap-wrap">
                                <div className="column is-6">
                                    <h2
                                        className="font-bold size-80 is-transparent text-stroke-white"
                                        style={{ wordBreak: 'break-all' }}
                                    >
                                        {data.contentfulReportsAndWhitepapersItem.type}
                                    </h2>
                                </div>
                                <div className="column is-6">
                                    <h1 className="size-60 is-white">
                                        {pageContext.title}
                                    </h1>
                                    <p className="size-28 is-purple-tertiary margin-top-20">
                                        {`Category: ${data.contentfulReportsAndWhitepapersItem.category}`}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="hero is-large is-hidden-tablet" style={{ background: `url(${getSrc(data.bg)}) no-repeat center/cover` }}>
                    <div className="hero-body">
                        <div className="container">
                            <div className="columns is-vcentered">
                                <div className="column is-6">
                                    <h2 className="font-bold size-50 is-transparent text-stroke-white">
                                        {data.contentfulReportsAndWhitepapersItem.type}
                                    </h2>
                                </div>
                                <div className="column is-6">
                                    <h1 className="size-50 is-white">
                                        {pageContext.title}
                                    </h1>
                                    <p className="size-28 is-purple-tertiary margin-top-20">
                                        {`Category: ${data.contentfulReportsAndWhitepapersItem.category}`}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section bg-grey">
                    <div className="container">
                        <div className="columns">
                            <div className="column">
                                <h2 className="font-variable size-56 is-transparent text-stroke-black">
                                    {data.contentfulReportsAndWhitepapersItem.overviewTitle}
                                </h2>

                                <ContentHTMLWithHoverPopUps
                                    contentHTML={data.contentfulReportsAndWhitepapersItem.overviewText.childMarkdownRemark.html}
                                    popUpItems={definedTermsPopUpItems}
                                    className="content"
                                />
                                <ShareButtons url={location.href} title={`${pageContext.title}`} />
                                <Button textClass="size-20" type="outlined" text="contact us" color="purple" to="/ask-us-anything" />
                            </div>

                            <div className="column is-hidden-mobile">
                                <div className="card is-white-bg sticky-card is-card-shadow" style={{ marginTop: "-140px" }}>
                                    <div className="card-content">
                                        <HubspotForm
                                            formId={data.contentfulReportsAndWhitepapersItem.hubSpotFormId}
                                            loading={<div>Loading...</div>}
                                            onSubmit={handleHubSpotFormSubmit}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {data.contentfulReportsAndWhitepapersItem.relatedArticles.length && (
                    <section className="section">
                            <div className="container">
                                <h2 className="ingress size-40 is-black">
                                    {data.contentfulReportsAndWhitepapersItem.relatedArticlesTitle}
                                </h2>
                                <div className="columns is-mobile is-multiline">
                                    {data.contentfulReportsAndWhitepapersItem.relatedArticles.map((post, i) => (
                                        <div className="column is-4-desktop is-12-mobile" key={i}>
                                            <ArticleItem
                                                item={post}
                                                color={post.color || ["green"]}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                    </section>
                )}
            </main>
        </Layout>
    );
}

export const query = graphql`
query ($contentful_id: String!, $contentful_content_vocabulary_item_slugs: [String]) {
    contentfulReportsAndWhitepapersItem (contentful_id: { eq: $contentful_id }) {
        slug
        type
        category
        hubSpotFormId
        overviewTitle
        overviewText {
            childMarkdownRemark {
                html
            }
        }
        relatedArticlesTitle
        relatedArticles {
            title
            articleUrl
            color
            subtitle
            date(formatString: "DD.MM.YYYY")
            category
            liftImage {
                gatsbyImageData(width: 1820, quality: 100)
                title
            }
        }
    }
    bg: file(relativePath: { eq: "images/purple_blurry_bg.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          gatsbyImageData(width: 1920, quality: 100)
        }
    }
    allContentfulDefinedTerm(
        filter: {slug: {in: $contentful_content_vocabulary_item_slugs}, node_locale: {eq: "en-US"}}
        ) {
        nodes {
            slug
            title
            description {
                childMarkdownRemark {
                    html
                    excerpt
                }
            }
        }
    }
}
`